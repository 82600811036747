@import url(https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto+Slab:wght@900&display=swap);
body {
  margin: 0;

  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
  'Oxygen',

    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',

    sans-serif;

  -webkit-font-smoothing: antialiased;

  -moz-osx-font-smoothing: grayscale; */
}

/* font-family: 'Montserrat', sans-serif;
font-family: 'Roboto Slab', serif; */

.gcard {
  margin: 20px;
}

.gcard-img {
  border-radius: 10px;
  width: 300px;
  height: 410px;
  box-shadow: 0 0 3px 1px  #ccc;
}

.gcard-info {
  margin-top: 10px;
  min-height: 100px;
}

.gacard-title {
  font-size: 24px;
  margin: 0;
}

.gcard-sub {
  font-size: 16px;
  margin: 0;
}

.success-msg {
  color: #57a773;
  padding: 10px 15px;
}

.err-msg {
  padding: 10px 15px;
  color: #ee6352;
}

/* Header */

.header-col-heading {
  font-family: 'Montserrat', sans-serif !important;
}

.header-col-heading > .name {
  font-family: 'Roboto Slab', serif !important;
  text-transform: uppercase;
}

.header-col-heading > .title {
  font-family: 'Montserrat', sans-serif !important;
  font-size: 0.8em !important;
}

.header-col-heading > hr {
  margin-top: -0.6em;
  margin-bottom: -0.1em;
  padding-bottom: 0.3em;
}

/* About Section */

.major > h2 {
  font-family: 'Roboto Slab', serif !important;
  font-weight: 900 !important;
  font-size: 3em !important;
  margin-bottom: 32px;
  color: #787878;
  line-height: 1.5em;
}

#one > p {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 400 !important;
  font-size: 1.2em !important;
  color: #a2a2a2;
  margin-bottom: 2em;
}

#next > h2 {
  font-family: 'Roboto Slab', serif !important;
  font-weight: 900 !important;
  font-size: 3em !important;
  color: #787878;
  line-height: 1.5em;
}

#next > p {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 400 !important;
  font-size: 1.2em !important;
  color: #a2a2a2;
}

.section-three > h2 {
  font-family: 'Roboto Slab', serif !important;
  font-weight: 900 !important;
  font-size: 3em !important;
  color: #787878;
  line-height: 1.5em;
}

.section-three > p {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 400 !important;
  font-size: 1.2em !important;
  color: #a2a2a2;
}

form > .form-group > .form-label {
  color: #a2a2a2 !important;
  font-family: 'Montserrat', sans-serif !important;
}

.row-col-color-1 {
  background: #1f1815;
  background-position: top left, center center;
  background-size: auto, cover;
  background-image: url(https://user-images.githubusercontent.com/25479050/85929387-374e8580-b8ac-11ea-8824-01ef6909d58a.png), url(https://user-images.githubusercontent.com/25479050/85928869-43d0df00-b8a8-11ea-842a-fc131cd27956.jpg);
  background-repeat: repeat, no-repeat;
  text-align: center;
  position: relative;
  min-height: 100vh;
}

#footer .copyright {
  color: rgba(255, 255, 255, 0.4);
  font-size: 0.8em;
  list-style: none;
  margin: 1em 0 0 0;
}

.row-inner {
  /* border: 1px solid black; */

  margin-top: 5em;
}

.image-avatar {
  margin-bottom: 1em;
  width: 123px;
  height: 123px;
}

.image-avatar img {
  border-radius: 100%;
  width: 100%;
}

h1 {
  font-size: 1.35em !important;
  color: rgba(255, 255, 255, 0.5);
  line-height: 1.75em;
  font-weight: 400;
  text-align: center;
}

h1 > a {
  transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  border-bottom: dotted 1px;
  font-size: 1em;
}

strong {
  color: #fff;
  font-weight: 400;
}

.row-col-color-2 {
  /* background: #ee6352; */

  background: #000;
  overflow-y: scroll;
  max-height: 100vh;
}

@media (min-width: 992px) {
  html {
    overflow-y: hidden !important;
  }
}

@media screen and (max-width: 768px) {
  html {
    overflow-y: scroll;
  }

  .row-col-color-2 {
    overflow-y: visible;
    max-height: 100%;
  }
}

@media screen and (max-width: 992px) {
  html {
    overflow-y: scroll !important;
  }

  .row-col-color-2 {
    max-height: 100%;
    overflow-y: hidden;
  }
}

@media screen and (max-width: 1200px) {
  html {
    overflow-y: hidden;
  }

  .image-avatar {
    text-align: right !important;
  }
}

/* Section one */

#About > section {
  margin-top: 4em;
  padding-top: 4em;
}

.main-button {
  /* border: 3px solid #787878 !important; */

  border: 3px solid #e0e2db !important;
  margin-bottom: 2em;
}

.main-button:hover {
  border: 3px solid #49bf9d !important;
  color: #49bf9d !important;
}

.main-button:hover .button {
  color: #49bf9d !important;
}

.button {
  transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  text-decoration: none;
  color: #a2a2a2 !important;

  /* color: #787878 !important; */

  background-color: transparent !important;
  font-weight: 400;
  line-height: 2em;
  min-width: 9em;
  padding-right: 1.5em;
  padding-left: 1.5em;

  /* padding: 0 1.5em; */
}

.button:hover {
  text-decoration: none;
  color: #49bf9d;
  background: none !important;
  transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

#next {
  margin-top: 4em;
  margin-bottom: 1em;
  padding-top: 4em;
  border-top: solid 2px #a2a2a2;
}

/* Section three Form */

#three {
  border-top: solid 2px #a2a2a2;
  margin-top: 4em;
  padding-top: 4em;
}

#three > h2 {
  font-size: 1.5em;
  line-height: 1.5em;
  color: #787878;
  font-weight: 400;

  /* margin: 0 0 1em 0; */

  /* margin-top: 0px;

  margin-right: 0px; */

  margin-bottom: 1em;

  /* margin-left: 0px; */
}

#three > p {
  /* margin-top: 0px; */

  /* margin-right: 0px; */

  margin-bottom: 2em;

  /* margin-left: 0px; */
}

/* Footer */

.footer-button {
  border: none !important;
}

.footer-button:hover {
  /* border: none !important; */
  background: none !important;
}

.footer-icon:hover {
  color: #49bf9d !important;

  /* background: none !important; */
}

.icons {
  margin-top: 1em;
}

ul.icons {
  list-style: none;
}

.copyright {
  text-align: center !important;
}

/* Section two */

.project-item-card {
  position: relative;
  border: none !important;
  transition: all  2s ease-in-out;

  /* filter: grayscale(100%); */
}

.project-item-card:hover {
  opacity: 0.3;
}

.project-item-card Button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 300ms 0ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.project-item-card:hover Button {
  transform: translate(-50%, -50%) scale(1);
  transition: transform 300ms 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.project-button {
  color: gray !important;
  background-color: #49bf9d !important;
  border: none !important;
}

/* Card */

.card-text {
  /* font-family: 'Roboto Slab', serif !important; */

  font-family: 'Montserrat', sans-serif !important;
  margin: auto;
  color: #787878;
}

/* Modal */

.modal-button > Button {
  background-color: transparent !important;
  font-weight: 400 !important;
  border: 2px solid gray !important;
}

.card-text > .tech-stack {
  font-family: 'Montserrat', sans-serif !important;
  text-transform: uppercase;
  color: #787878 !important;
  font-weight: bold;
  font-size: 1em !important;
  line-height: 0 !important;
}

.card-text > .features {
  font-family: 'Montserrat', sans-serif !important;
  text-transform: uppercase;
  color: #787878 !important;
  font-weight: bold;
}

.live > .modal-live-button {
  /* color: #ffffff !important; */

  color: #787878 !important;
}

.live:hover .modal-live-button {
  text-decoration: none !important;
  color: #49bf9b !important;

  /* border: 2px solid #49bf9d !important; */
}

.modal-github-button {
  color: gray !important;
}

.modal-github-button:hover {
  color: #49bf9b !important;
}

.github:hover {
  border: 2px solid #49bf9d !important;
}

.live:hover {
  border: 2px solid #49bf9d !important;
}

.modal-close-button {
  background-color: transparent !important;
  font-weight: 400 !important;
  border: 2px solid gray !important;
  color: #787878 !important;
}

.modal-close-button:hover {
  color: #49bf9b !important;
  border: 2px solid #49bf9d !important;
}

.App {
  text-align: center;
}

/*
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

